@import 'src/styles/variables/variables';

@include useTheme(2025);

.section {
  background-color: #F3F0EB;
}

.container {
  max-width: 1336px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  padding: 2.75rem 13px 4.55rem;
  text-align: center;

  .CTAButton {
    margin: 23px auto 30px;
    height: 55px;
  }

  @include min-width(tablet) {
    padding-top: 4.15rem;
    padding-bottom: 5.5rem;

    .CTAButton {
      height: 52px;
      padding: 0 35px;
    }
  }
}

.h2 {
  @include secondaryFont();
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -.4px;
  text-align: center;
  margin: 0 0 36px;

  span {
    @include primaryFont(400);
    font-size: 14px;
    display: block;
    margin-top: 10px;
    line-height: 20px;

    @include min-width(tablet) {
      font-size: 16px;
    }
  }

  @include min-width(tablet) {
    font-size: 48px;
    line-height: 58px;
    margin: 0 0 48px;
  }

  br {
    display: none;

    @include min-width(tablet) {
      display: block;
    }
  }
}

.tabsWrapper {
  position: relative;
  margin: 0 -13px;

  @include min-width(tablet) {
    //margin: 0 -13px 50px;
    margin: 0 -23px 50px;
    padding: 0 30px;
  }

  @include min-width(large-desktop) {
    margin: 0 -13px 50px;
    padding: 0;
  }

  &::before,
  &::after {
    content: '';
    width: 20px;
    height: 50px;
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;
    -webkit-touch-callout: none;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, #F3F0EB 2px, rgba(#F3F0EB, 0));
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, #F3F0EB 2px, rgba(#F3F0EB, 0));
  }

  .topLevelTabs {
    display: flex;
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    scrollbar-width: none;
    border: none;
    padding: 0 17px;
    gap: 0 18px;
    margin: 0 0 3rem;

    &::-webkit-scrollbar {
      display: none;
    }

    &::before, &::after {
      content: '';
      display: block;
      width: 18px;
      margin: 0 -18px 0 auto;
      height: 100%;
    }

    &::after {
      margin: 0 auto 0 -18px;
    }

    @include min-width(tablet) {
      margin: 0 0 40px;
      gap: 0 18px;
    }
  }

  .topLevelTab {
    @include primaryFont();
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 0 13px;
    height: 38px;
    border: none;
    font-size: 16px;
    bottom: 0;

    &::after {
      display: none;
    }

    &:hover, &[class*="tabs__tab--selected"] {
      background: $macadamia;
      color: $almond;

      img {
        /* target is $almond(#906136): https://somelittletools.com/en/generators/svg-css-coloring/ */
        filter: invert(36%) sepia(80%) saturate(357%) hue-rotate(347deg) brightness(70%) contrast(85%);
      }
    }

    @include min-width(tablet) {
      padding: 0 26px;
    }
  }

  .tabIcon {
    margin: 0;
    transform: scale(.67);
    transform-origin: left;

    @include min-width(tablet) {
      margin: 0 8px 0 0;
    }
  }

  .secondLevelTabs {
    display: flex;
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    scrollbar-width: none;
    padding: 0 17px;
    margin: 0;
    gap: 0 13px;
    border: none;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    @include min-width(tablet) {
      padding: 0 3px;
      gap: 0 34px;
    }
  }

  .secondLevelTab {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    color: #999;
    transition: color .4s;
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
    bottom: 0;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 6px;
      background: #362B24;
      transition: opacity .4s;
      opacity: 0;
    }

    &::after {
      display: none;
    }

    &:hover {
      .secondLevelTabLabel {
        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }
    }

    &[class*="tabs__tab--selected"] {
      background: none;
      color: #362B24;

      &::before {
        opacity: 1;
      }

      .secondLevelTabLabel {
        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }
    }

    @include min-width(tablet) {
      font-size: 16px;
      padding: 2px 7px 0;
    }
  }

  .secondLevelTabPanel {
    padding: 27px 13px 17px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 17px;
      right: 17px;
      height: 1px;
      background: #5C5C5C;
    }

    @include min-width(tablet) {
      padding: 12px 0;

      &::before {
        left: 3px;
        right: 3px;
      }
    }
  }

  .secondLevelTabLabel {
    color: transparent;
    display: block;
    position: relative;
    font-weight: 400;

    &::before,
    &::after {
      font-weight: 400;
      content: attr(data-text);
      speak: none;
      color: #999;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      opacity: 1;
      transition: opacity .1s ease;
    }

    &::after {
      color: #362B24;
      font-weight: 500;
      opacity: 0;
    }
  }

  .slideImage {
    width: 100%;
    height: 100%;
    animation: skeleton-loading 1s linear infinite alternate;
  }
}

.slideWrapper {
  position: relative;
  padding: calc(218 / 349 * 100%) 0 0;

  & > [class*="ComparisonSlider_root"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & [class*="Item_comparisonSliderItem"] {
    height: 100% !important;
  }

  @include min-width(small-tablet) {
    padding-top: calc(841 / 1009 * 100%);
  }

  @include min-width(desktop) {
    padding-top: calc(841 / 1250 * 100%);
  }

  @include min-width(large-desktop) {
    padding-top: calc(750 / 1334 * 100%);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(210, 6%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}